import React from "react";

export const majors = [
  {label: "Art", value: "art", description: "Expression or application of human creative skill and imagination"},
  {label: "Astronomy", value: "astronomy", description: "Study of celestial objects such as stars, planets, comets, and galaxies"},
  {label: "Biology", value: "biology", description: "Study of living organisms and their interactions with each other and their environments"},
  {label: "Business Administration", value: "business-administration", description: "Study of management, marketing, finance, and human resources"},
  {label: "Chemistry", value: "chemistry", description: "Study of matter, its properties, how and why substances combine or separate to form other substances"},
  {label: "Communication", value: "communication", description: "Study of conveying information and ideas through various mediums"},
  {label: "Computer Science", value: "computer-science", description: "Study of computers and computational systems"},
  {label: "Data Science", value: "data-science", description: "Study of extracting insights and knowledge from data"},
  {label: "Economics", value: "economics", description: "Study of how individuals, businesses, governments, and nations make choices about how to allocate resources"},
  {label: "Engineering", value: "engineering", description: "Application of scientific, economic, social, and practical knowledge in order to design, build, and maintain structures, machines, devices, systems, materials, and processes"},
  {label: "Environmental Science", value: "environmental-science", description: "Study of the interactions among the physical, chemical, and biological components of the environment"},
  {label: "Finance", value: "finance", description: "Study of investments, money management, and the evaluation of risks and future outcomes"},
  {label: "Geology", value: "geology", description: "Study of the Earth's physical structure and substance, its history, and the processes that act on it"},
  {label: "History", value: "history", description: "Study of past events"},
  {label: "Information Technology", value: "information-technology", description: "Study of the use of systems for storing, retrieving, and sending information"},
  {label: "Law", value: "law", description: "Study of the legal system and principles of justice"},
  {label: "Marketing", value: "marketing", description: "Study of promoting and selling products or services, including market research and advertising"},
  {label: "Mathematics", value: "mathematics", description: "Study of quantity, structure, space, and change"},
  {label: "Medicine", value: "medicine", description: "Study of the diagnosis, treatment, and prevention of disease"},
  {label: "Nursing", value: "nursing", description: "Study of healthcare and patient care"},
  {label: "Philosophy", value: "philosophy", description: "Study of fundamental questions about existence, knowledge, values, reason, mind, and language"},
  {label: "Physics", value: "physics", description: "Study of matter, energy, space, and time"},
  {label: "Political Science", value: "political-science", description: "Study of politics and government"},
  {label: "Pre-law", value: "pre-law", description: "Preparation for the study of law"},
  {label: "Psychology", value: "psychology", description: "Study of mind and behavior"},
  {label: "Sociology", value: "sociology", description: "Study of society, patterns of social relationships, social interaction, and culture of everyday life"},
  {label: "Sports", value: "sports", description: "Study of physical activity, exercise, and sports management"},
  {label: "Technology", value: "technology", description: "Study of the application of scientific knowledge for practical purposes"},
  {label: "Writing", value: "writing", description: "Study of written expression and composition"},
];

